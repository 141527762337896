<script lang="ts" setup>
//import "./assets/css/green-light/theme.css"
import { useDark } from '@vueuse/core'
// Composition API
import { nl } from "~/assets/locale/nl.json";
import { en } from "~/assets/locale/en.json";

import { usePrimeVue } from 'primevue/config';

const isDark = useDark();
watch(isDark, () => {
  if (isDark.value)
    document.documentElement.classList.add('p-dark')
  else
    document.documentElement.classList.remove('p-dark')
})

const { locale } = useI18n()
const primevue = usePrimeVue();
primevue.config.locale = locale.value == 'en' ? en : nl;
watch(locale, () => {
    primevue.config.locale = locale.value == 'en' ? en : nl;
})


if (isDark.value)
  document.documentElement.classList.add('p-dark')

const { auth } = useSupabaseClient();
const route = useRoute();
const subscription = auth.onAuthStateChange((event, session) => {
  console.log(route.params);
  if (event === 'INITIAL_SESSION') {
    // handle initial session
  } else if (event === 'SIGNED_IN') {
    //navigateTo('/')
  } else if (event === 'SIGNED_OUT' && !route.params.invitation_id) {
    navigateTo('/auth/login');
  } else if (event === 'PASSWORD_RECOVERY') {
    navigateTo('/auth/recover');
    // handle password recovery event
  } else if (event === 'TOKEN_REFRESHED') {
    // handle token refreshed event
  } else if (event === 'USER_UPDATED') {
    // handle user updated event
  }
})
</script>

<template>
  <NuxtPage />
</template>