import { default as confirmazeHrnoeEOMeta } from "C:/Projects/Personal/tiqhet/pages/auth/confirm.vue?macro=true";
import { default as forgot_45passwordj4JnHkHwWxMeta } from "C:/Projects/Personal/tiqhet/pages/auth/forgot-password.vue?macro=true";
import { default as _91invitation_id_93H5AfOIoXkeMeta } from "C:/Projects/Personal/tiqhet/pages/auth/join/[invitation_id].vue?macro=true";
import { default as loginPqfH4nbb92Meta } from "C:/Projects/Personal/tiqhet/pages/auth/login.vue?macro=true";
import { default as recoverYlKnc92BXvMeta } from "C:/Projects/Personal/tiqhet/pages/auth/recover.vue?macro=true";
import { default as registerHWLrc0WVpDMeta } from "C:/Projects/Personal/tiqhet/pages/auth/register.vue?macro=true";
import { default as strongPassword63DaDQw9oQMeta } from "C:/Projects/Personal/tiqhet/pages/auth/strongPassword.ts?macro=true";
import { default as auth4WS5zIl7z5Meta } from "C:/Projects/Personal/tiqhet/pages/auth.vue?macro=true";
import { default as account49wTac6c1XMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/account.vue?macro=true";
import { default as clearingsOtD6Gef1OHMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/clearings.vue?macro=true";
import { default as dashboardpBQSfBuuXEMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/dashboard.vue?macro=true";
import { default as _91_91ticket_design_id_93_93ZHMWC6TAZ8Meta } from "C:/Projects/Personal/tiqhet/pages/index/index/designer/[[ticket_design_id]].vue?macro=true";
import { default as defaultTemplatepnmf6cSaKCMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/designer/defaultTemplate.ts?macro=true";
import { default as emailsSoQoCofkOXMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/emails.vue?macro=true";
import { default as add_45questso1J7Pd61kPMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/add-quests.vue?macro=true";
import { default as agendarMN5aUa2FVMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/agenda.vue?macro=true";
import { default as _91order_id_93dGymmH86NLMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/orders/[order_id].vue?macro=true";
import { default as indexz9ndOR1sK7Meta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/orders/index.vue?macro=true";
import { default as overvieww6r8DVvZdLMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/overview.vue?macro=true";
import { default as seatsFMgyc86kPgMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/seats.vue?macro=true";
import { default as indexEjZxPiWwiYMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/settings/index.vue?macro=true";
import { default as metadataO3qrYNRr0wMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/settings/metadata.vue?macro=true";
import { default as moreUfHZzDFusuMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/settings/more.vue?macro=true";
import { default as themess8w1byqORMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/settings/theme.vue?macro=true";
import { default as ticket_45typesmvP4XA9s2nMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/settings/ticket-types.vue?macro=true";
import { default as timeslotszMTZGDeRNqMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/settings/timeslots.vue?macro=true";
import { default as settings4bqyiBcNIJMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/settings.vue?macro=true";
import { default as ticketslqFyyt38MTMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/tickets.vue?macro=true";
import { default as _91event_id_93ijCAJXjVBrMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id].vue?macro=true";
import { default as archive1EKO4XdUzHMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/index/archive.vue?macro=true";
import { default as index0qomw15PJwMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/index.vue?macro=true";
import { default as newi1qiJJW7phMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/events/new.vue?macro=true";
import { default as indexwmCrHBTaHqMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/index/index.vue?macro=true";
import { default as refreshC90MgZHiKgMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/onboarding/refresh.vue?macro=true";
import { default as returnmsAGtbCrf6Meta } from "C:/Projects/Personal/tiqhet/pages/index/index/onboarding/return.vue?macro=true";
import { default as orders1CqDEermEoMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/orders.vue?macro=true";
import { default as manageMNNHd2P6OzMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/organization/manage.vue?macro=true";
import { default as _91_91ticket_design_id_93_93nVKFkFjyMjMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/report-designer/[[ticket_design_id]].vue?macro=true";
import { default as defaultTemplatemm7YjictaIMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/report-designer/defaultTemplate.ts?macro=true";
import { default as all0urWTMZrX3Meta } from "C:/Projects/Personal/tiqhet/pages/index/index/scanners/all.vue?macro=true";
import { default as profiles0x7vvp1kTUMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/scanners/profiles.vue?macro=true";
import { default as scannersGCO8nr28AbMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/scanners.vue?macro=true";
import { default as seatmapAmcDATrpYuMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/seatmap.vue?macro=true";
import { default as organizationrXefZHDjrTMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/settings/organization.vue?macro=true";
import { default as paymentsSKVUtr5dnSMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/settings/payments.vue?macro=true";
import { default as themeEdQUYZTjPyMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/settings/theme.vue?macro=true";
import { default as ticketss0WA7FgxXgMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/settings/tickets.vue?macro=true";
import { default as usersObkgAU5uslMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/settings/users.vue?macro=true";
import { default as settingsUkuMmmXDoGMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/settings.vue?macro=true";
import { default as _91id_93e0T1zpzzkDMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/transactions/[id].vue?macro=true";
import { default as indexetMgnGHNYNMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/transactions/index.vue?macro=true";
import { default as refundsry6dOnYYp6Meta } from "C:/Projects/Personal/tiqhet/pages/index/index/transactions/refunds.vue?macro=true";
import { default as _91order_id_93diLyWDd2fUMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/visitors/orders/[order_id].vue?macro=true";
import { default as indexmKWAqdpqRqMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/visitors/orders/index.vue?macro=true";
import { default as visitors7RgSgVVK1SMeta } from "C:/Projects/Personal/tiqhet/pages/index/index/visitors.vue?macro=true";
import { default as index7vTWHJUJTjMeta } from "C:/Projects/Personal/tiqhet/pages/index/index.vue?macro=true";
import { default as new_45organizationiH0CzikrGCMeta } from "C:/Projects/Personal/tiqhet/pages/index/new-organization.vue?macro=true";
import { default as indexUrVxoUDSrsMeta } from "C:/Projects/Personal/tiqhet/pages/index.vue?macro=true";
import { default as unsupported_45browserIaOaUAfKNQMeta } from "C:/Projects/Personal/tiqhet/pages/unsupported-browser.vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    component: () => import("C:/Projects/Personal/tiqhet/pages/auth.vue"),
    children: [
  {
    name: "auth-confirm",
    path: "confirm",
    component: () => import("C:/Projects/Personal/tiqhet/pages/auth/confirm.vue")
  },
  {
    name: "auth-forgot-password",
    path: "forgot-password",
    component: () => import("C:/Projects/Personal/tiqhet/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-join-invitation_id",
    path: "join/:invitation_id()",
    component: () => import("C:/Projects/Personal/tiqhet/pages/auth/join/[invitation_id].vue")
  },
  {
    name: "auth-login",
    path: "login",
    component: () => import("C:/Projects/Personal/tiqhet/pages/auth/login.vue")
  },
  {
    name: "auth-recover",
    path: "recover",
    component: () => import("C:/Projects/Personal/tiqhet/pages/auth/recover.vue")
  },
  {
    name: "auth-register",
    path: "register",
    component: () => import("C:/Projects/Personal/tiqhet/pages/auth/register.vue")
  },
  {
    name: "auth-strongPassword",
    path: "strongPassword",
    component: () => import("C:/Projects/Personal/tiqhet/pages/auth/strongPassword.ts")
  }
]
  },
  {
    name: indexUrVxoUDSrsMeta?.name,
    path: "/",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index.vue"),
    children: [
  {
    name: index7vTWHJUJTjMeta?.name,
    path: "",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index.vue"),
    children: [
  {
    name: "index-index-account",
    path: "account",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/account.vue")
  },
  {
    name: "index-index-clearings",
    path: "clearings",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/clearings.vue")
  },
  {
    name: "index-index-dashboard",
    path: "dashboard",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/dashboard.vue")
  },
  {
    name: "index-index-designer-ticket_design_id",
    path: "designer/:ticket_design_id?",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/designer/[[ticket_design_id]].vue")
  },
  {
    name: "index-index-designer-defaultTemplate",
    path: "designer/defaultTemplate",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/designer/defaultTemplate.ts")
  },
  {
    name: "index-index-emails",
    path: "emails",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/emails.vue")
  },
  {
    name: "index-index-events-event_id",
    path: "events/:event_id()",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id].vue"),
    children: [
  {
    name: "index-index-events-event_id-add-quests",
    path: "add-quests",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/add-quests.vue")
  },
  {
    name: "index-index-events-event_id-agenda",
    path: "agenda",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/agenda.vue")
  },
  {
    name: "index-index-events-event_id-orders-order_id",
    path: "orders/:order_id()",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/orders/[order_id].vue")
  },
  {
    name: "index-index-events-event_id-orders",
    path: "orders",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/orders/index.vue")
  },
  {
    name: "index-index-events-event_id-overview",
    path: "overview",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/overview.vue")
  },
  {
    name: "index-index-events-event_id-seats",
    path: "seats",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/seats.vue")
  },
  {
    name: settings4bqyiBcNIJMeta?.name,
    path: "settings",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/settings.vue"),
    children: [
  {
    name: "index-index-events-event_id-settings",
    path: "",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/settings/index.vue")
  },
  {
    name: "index-index-events-event_id-settings-metadata",
    path: "metadata",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/settings/metadata.vue")
  },
  {
    name: "index-index-events-event_id-settings-more",
    path: "more",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/settings/more.vue")
  },
  {
    name: "index-index-events-event_id-settings-theme",
    path: "theme",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/settings/theme.vue")
  },
  {
    name: "index-index-events-event_id-settings-ticket-types",
    path: "ticket-types",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/settings/ticket-types.vue")
  },
  {
    name: "index-index-events-event_id-settings-timeslots",
    path: "timeslots",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/settings/timeslots.vue")
  }
]
  },
  {
    name: "index-index-events-event_id-tickets",
    path: "tickets",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/[event_id]/tickets.vue")
  }
]
  },
  {
    name: "index-index-events",
    path: "events",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/index.vue"),
    children: [
  {
    name: "index-index-events-index-archive",
    path: "archive",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/index/archive.vue")
  }
]
  },
  {
    name: "index-index-events-new",
    path: "events/new",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/events/new.vue")
  },
  {
    name: "index-index-index",
    path: "",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/index/index.vue")
  },
  {
    name: "index-index-onboarding-refresh",
    path: "onboarding/refresh",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/onboarding/refresh.vue")
  },
  {
    name: "index-index-onboarding-return",
    path: "onboarding/return",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/onboarding/return.vue")
  },
  {
    name: "index-index-orders",
    path: "orders",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/orders.vue")
  },
  {
    name: "index-index-organization-manage",
    path: "organization/manage",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/organization/manage.vue")
  },
  {
    name: "index-index-report-designer-ticket_design_id",
    path: "report-designer/:ticket_design_id?",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/report-designer/[[ticket_design_id]].vue")
  },
  {
    name: "index-index-report-designer-defaultTemplate",
    path: "report-designer/defaultTemplate",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/report-designer/defaultTemplate.ts")
  },
  {
    name: "index-index-scanners",
    path: "scanners",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/scanners.vue"),
    children: [
  {
    name: "index-index-scanners-all",
    path: "all",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/scanners/all.vue")
  },
  {
    name: "index-index-scanners-profiles",
    path: "profiles",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/scanners/profiles.vue")
  }
]
  },
  {
    name: "index-index-seatmap",
    path: "seatmap",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/seatmap.vue")
  },
  {
    name: "index-index-settings",
    path: "settings",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/settings.vue"),
    children: [
  {
    name: "index-index-settings-organization",
    path: "organization",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/settings/organization.vue")
  },
  {
    name: "index-index-settings-payments",
    path: "payments",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/settings/payments.vue")
  },
  {
    name: "index-index-settings-theme",
    path: "theme",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/settings/theme.vue")
  },
  {
    name: "index-index-settings-tickets",
    path: "tickets",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/settings/tickets.vue")
  },
  {
    name: "index-index-settings-users",
    path: "users",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/settings/users.vue")
  }
]
  },
  {
    name: "index-index-transactions-id",
    path: "transactions/:id()",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/transactions/[id].vue")
  },
  {
    name: "index-index-transactions",
    path: "transactions",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/transactions/index.vue")
  },
  {
    name: "index-index-transactions-refunds",
    path: "transactions/refunds",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/transactions/refunds.vue")
  },
  {
    name: "index-index-visitors",
    path: "visitors",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/visitors.vue"),
    children: [
  {
    name: "index-index-visitors-orders-order_id",
    path: "orders/:order_id()",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/visitors/orders/[order_id].vue")
  },
  {
    name: "index-index-visitors-orders",
    path: "orders",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/index/visitors/orders/index.vue")
  }
]
  }
]
  },
  {
    name: "index-new-organization",
    path: "new-organization",
    component: () => import("C:/Projects/Personal/tiqhet/pages/index/new-organization.vue")
  }
]
  },
  {
    name: "unsupported-browser",
    path: "/unsupported-browser",
    component: () => import("C:/Projects/Personal/tiqhet/pages/unsupported-browser.vue")
  }
]